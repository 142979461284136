@import './responsive';

.sidebar-menu {
  position: relative;
  height: 100vh;
  background-color: #313447;

  @include respond-to(md) {
    position: absolute;
    width: 100%;
    left: -100%;
    transition: left 0.3s;
    z-index: 99;
  }

  .brand-logo {
    padding: 40px 0px;
    text-align: center;
    margin-bottom: 40px;
    position: relative;

    img {
      width: 200px;
    }
  }

  .nav-menu {
    padding: 0px;

    .nav-item {
      list-style: none;

      a {
        display: block;
        padding: 12px 16px;
        transition: ease-in-out 0.2s;
        color: #60687D;
        text-decoration: none;
        font-weight: 500;
        border-left: 2px solid transparent;

        &:hover {
          background-color: #313F57;
        }

        span {
          position: relative;
          bottom: 6px;
          left: 16px;
        }
      }

      a.active {
        color: #4FC3F7;
        background-color: #313F57;
        border-left: 2px solid;
      }

      .dashboard {
        &::before {
          content: url("../images/request.svg");
        }
      }

      .dashboard.active {
        &::before {
          content: url("../images/request.svg");
        }
      }

      img {
        height: 24px;
        width: 24px;
        color: #60687d;
      }
    }

    .side-menu-heading {
      color: #999AA4;
      font-size: 20px;
      font-weight: 600;
      text-align: left;
      padding: 12px 16px;
    }
  }

  .toggle-button {
    position: absolute;
    background-color: #313447;
    color: white;
    border: none;
    padding: 30px 6px;
    cursor: pointer;
    right: -20px;
    top: 24px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;

    @include respond-to(md) {
      display: block;
      z-index: 99;
    }

  }
}

@include respond-to(md) {
  .sidebar-menu.collapsed-menu {
    left: 0;
    z-index: 999;
    width: 60px;
  }
}