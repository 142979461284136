@import "~bootstrap/scss/bootstrap";
@import url("./_sidebar.scss");
@import url("./_welcome.scss");
@import url("./_header.scss");
@import url("./_common.scss");
@import './responsive';

* {
  font-family: 'Poppins';
}

body {
  margin: 0px auto;
}

.background {
  background-color: #19253a;
}

.bg-darkMode {
  background-color: #1c1c1c;
}


.main-layout {
  width: 100%;
  height: 100vh;
  display: flex;
  padding: 0px;
  margin: 0px auto;
  overflow: hidden;
  position: relative;


  .sidebar-menu {
    min-width: 250px;
    transition: ease-out 0.2s;
  }

  .collapsed-menu {
    min-width: 60px;
    transition: ease-in 0.2s;

    .collapse-brand-logo {
      width: 80px;
      padding: 30px 8px;

      img {
        @include respond-to(md) {
          width: 36px;
          position: relative;
          left: 6px;
          top: 10px;
        }
      }
    }

  }

  .right-container {
    width: calc(100% - 60px);
    overflow: auto;
    padding: 16px 32px;

    @include respond-to(md) {
      width: 100%;
      padding: 12px;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .inner-container {
      box-sizing: border-box;
      border-radius: 8px;
    }
  }
}