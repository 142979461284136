.ag-theme-quartz {
  --ag-header-foreground-color: #1c1c1c;
  --ag-row-hover-background-color: #eaeff1;
  --ag-selected-row-background-color: #eaeff1;
  --ag-foreground-color: #1c1c1c;
  --ag-background-color: #fff;
  --ag-data-color: #777777;
  /* --ag-borders-critical: none; */
  --ag-card-radius: 8px;
  --ag-card-shadow: #666666;
  /* --ag-border-color: transparent; */
}

.ag-theme-quartz-dark {
  --ag-header-background-color: #1c1c1c;
  --ag-header-foreground-color: #ffffff;
  --ag-even-row-background-color: #333333;
  --ag-row-hover-background-color: #555555;
  --ag-selected-row-background-color: #666666;
  --ag-header-cell-hover-background-color: #777777;
  --ag-background-color: #1c1c1c;
  /* --ag-borders-critical: none; */
  --ag-card-radius: 8px;
  --ag-card-shadow: #ffffff;

  /* --ag-border-color: transparent;  */
}

.icon {
  font-size: 35px;
}

.header-bold {
  font-weight: 700;
  font-size: 16px;
}

.header-center {
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-pending:hover {
  background-color: #f6fcf8;
}

.row-approved:hover {
  background-color: #f6fcf8;
}

.row-rejected:hover {
  background-color: #f5ecec;
}

.row-pending {
  background-color: #f6fcf8;
}

.row-approved {
  background-color: #f6fcf8;
}

.row-rejected {
  background-color: #f5ecec;
}

