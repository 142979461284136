@mixin commonBadge {
  padding: 6px 8px;
  border-radius: 4px;
  font-size: 11px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.5px
}

.success-badge {
  @include commonBadge;
  background-color: #ddfae6;
  color: #16a34a;
}

.success-badge-outline {
  @include commonBadge;
  background-color: #1c1c1c;
  color: #16a34a;
  border: 1px solid #16a34a;
}

.pending-badge {
  @include commonBadge;
  background-color: #fff7ed;
  color: #ea580c;
}

.pending-badge-outline {
  @include commonBadge;
  background-color: #1c1c1c;
  border: 1px solid #ea580c;
  color: #ea580c;
}

.danger-badge {
  @include commonBadge;
  background-color: #FEF2F2;
  color: #da1414;
}

.danger-badge-outline {
  @include commonBadge;
  background-color: #FEF2F2;
  border: 1px solid #da1414;
  color: #da1414;
}

.muted-badge {
  @include commonBadge;
  background-color: #F5F5F5;
  color: #525252;
}

.muted-badge-outline {
  @include commonBadge;
  background-color: #1c1c1c;
  border: 1px solid #525252;
  color: #525252;
}

.danger-badge {
  @include commonBadge;
  background-color: #f5ecec;
  color: #da1414;
}


.danger-badge-outline {
  @include commonBadge;
  background-color: #1c1c1c;
  border: 1px solid #da1414;
  color: #da1414;
}



.text-blue {
  color: #4398CD
}

.text-orange {
  color: #ea580c;

}

.text-red {
  color: #da1414;

}

.text-green {
  color: #16a34a;

}

.text-muted {
  color: #525252;
}


// Buttons
@mixin commonButton {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 400;
  transition: 0.3s;

  cursor: pointer;

  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-16 px);
  }

  &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }
}

@mixin disabledButton {
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    box-shadow: none;
    transition: none;

    &:hover {
      background: none;
      box-shadow: none;
      transition: none;
    }
  }
}

.dsm-btn-primary {
  @include commonButton;
  background-color: #4398CD;
  border: 1px solid #4398CD;
  color: #fff;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;


  &:hover {
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
    transform: translateY(-1px);
    background-color: #307BAA;
    border: 1px solid #307BAA;
  }

  &:active {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    transform: translateY(1px);
  }

  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.dsm-btn-primary-outline {
  @include commonButton;
  background-color: #1c1c1c;
  border: 1px solid #4398CD;
  color: #4398CD;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 5px 5px 15px #4398CD48;

    transform: translateY(-1px);
    background-color: #1c1c1c;
    border: 1px solid #307BAA;
  }

  &:active {
    box-shadow: 5px 5px 15px #4398CD48;

    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


.dsm-btn-danger {
  @include commonButton;
  color: #fff;
  background-color: #da1414;
  border: 1px solid #da1414;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


.dsm-btn-danger-outline {
  @include commonButton;
  color: #da1414;
  background-color: #1c1c1c;
  border: 1px solid #da1414;
  transition: box-shadow 0.3s ease-in-out, transform 0.3s ease-in-out;

  &:hover {
    box-shadow: 5px 5px 15px #da141478;

    transform: translateY(-1px);
    background-color: #1c1c1c;
    border: 1px solid #da1414;
  }

  &:active {
    box-shadow: 5px 5px 15px #da141478;

    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}


.dsm-btn-secondary {
  @include commonButton;
  color: #4398CD;
  background-color: #fff;
  border: 1px solid #4398CD;


  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:hover {
    background-color: #E5F5FF;
    border-color: #307BAA
  }

  @include disabledButton;
}

.dsm-btn-light {
  @include commonButton;
  color: #afb4b7;
  // font-size: 18px;
  background-color: #1c1c1c;
  border: 1px solid #afb4b7;

  .btn-icon {
    position: relative;
    right: 4px;
  }

  &:hover {
    background-color: #1c1c1c;
    border-color: #afb4b7
  }


  &:hover {
    box-shadow: 5px 5px 15px #afb4b778;

    transform: translateY(-1px);
    background-color: #1c1c1c;
    border: 1px solid #afb4b7;
  }

  &:active {
    box-shadow: 5px 5px 15px #afb4b778;

    transform: translateY(1px);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.dsm-btn-sm {
  padding: 6px 8px;
  font-size: 12px;
}

// dsm modal popup{

.dsm-modal-popup {
  .modal-header {
    .modal-title {

      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
      color: #1C1C1C;

      img {
        position: relative;
        bottom: 1px;
      }
    }

    .btn-close {
      font-size: 13px;
      box-shadow: none;
    }

  }

  .modal-body {
    p {
      color: #777777;
      text-align: left;
    }
  }
}