@import 'responsive';

.landing-page-main {
    .body {
        padding: 50px 200px;
        color: #ffffff;
        background-color: #19253A;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond-to(sm) {
            padding: 50px;
        }

        @include respond-to(lg) {
            padding: 50px;
        }

        @include respond-to(md) {
            padding: 50px;
        }

        @include respond-to(xsm) {
            padding: 50px;
        }

        .brand-logo {
            margin-bottom: 16px;

            img {
                margin-bottom: 0px;
            }
        }

        h1 {
            margin-bottom: 16px;
            font-size: 32px;
        }

        p {
            margin-bottom: 16px;
            color: #9A9FA8;
            text-align: start;
        }

        .dsm-btn-primary {
            background-color: #0076FC;
            border-radius: 0px;
            border-color: #0076FC;
            height: 40px;
            color: #fff;
            font-size: 16px;

            @include respond-to(xsm) {
                width: 100% !important;
            }

        }

        .decentral-logo {
            img {
                margin-bottom: 0px;
            }

            @include respond-to(xsm) {
                display: none;
            }

            @include respond-to(sm) {
                display: none;
            }
        }
    }


    .footer {
        background-color: #111C2F;
        height: 40%;
        display: flex;
        align-items: baseline;
        justify-content: flex-start;
        padding: 32px 200px;
        color: #ffffff;
        gap: 16px;

    }
}